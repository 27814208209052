@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  overflow-x: hidden;
}
.hero {
  background-image: url("../src/assets/images/sofa.avif");
}
.bg-image {
  background-image: url("../src/assets/images/sofa.avif");
  
}
.image1 {
  background-image: linear-gradient(to bottom, #ffffff0b, #000000b2),
    url("../src/assets/images/Img1.png");
}
.image2 {
  background-image: linear-gradient(to bottom, #ffffff0b, #000000b2),
    url("../src/assets/images/img2.png");
  width: 400px;
  height: 490px;
  background-size: cover;
  background-repeat: no-repeat;
}
.image3 {
  background-image: linear-gradient(to bottom, #ffffff0b, #000000b2),
    url("../src/assets/images/img3.png");
}
.image4 {
  background-image: linear-gradient(to bottom, #ffffff0b, #000000b2),
    url("../src/assets/images/img4.png");
}
.image5 {
  background-image: linear-gradient(to bottom, #ffffff0b, #000000b2),
    url("../src/assets/images/img5.png");
}
.image6 {
  background-image: linear-gradient(to bottom, #ffffff0b, #000000b2),
    url("../src/assets/images/img6.png");
}

.hero-image {
  background-image: url("../src/assets/images/hero.avif"),
    linear-gradient(#0d0d0dbf, rgba(0, 0, 0, 0.5));
  background-blend-mode: overlay;
}

::placeholder {
  position: relative;

  font-family: "inter", "sans-serif";
  font-weight: 500;
  font-size: 16px;
  color: #b3b3b3;
}

.input::placeholder {
  position: relative;

  font-family: "inter", "sans-serif";
  font-weight: 500;
  font-size: 16px;
  color: #010101;
}
.custom-dot {
  color: #fff;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 5px;
}
